import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useCallback, useEffect } from "react";
import { useGetPaymentAccountsQuery } from "@/features/app/appApiSlice";
import { handleError } from "@/utils/handleError";
import * as Styled from "./SelectPaymentAccount.styles";

export interface ISelectPaymentAccountParams {
  paymentAccountType: string;
}

const PaymentAccountSelectLabel = "Payment Account";

export const SelectPaymentAccount = () => {
  const { data, isFetching, error } = useGetPaymentAccountsQuery();
  const { paymentAccounts } = data || {};

  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<ISelectPaymentAccountParams>();
  const { paymentAccountType } = parsedSearchParams || "";

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  useEffect(() => {
    if (paymentAccounts?.length && !paymentAccountType) {
      mergeSearchParams({
        paymentAccountType: paymentAccounts[0],
      });
    }
  }, [mergeSearchParams, paymentAccountType, paymentAccounts]);

  const handlePaymentAccountChange = useCallback(
    (event: SelectChangeEvent) => {
      mergeSearchParams({
        paymentAccountType: event?.target.value,
      });
    },
    [mergeSearchParams],
  );

  return (
    <Styled.FormControl disabled={isFetching}>
      <InputLabel>{PaymentAccountSelectLabel}</InputLabel>
      <Select
        value={paymentAccountType || ""}
        label={PaymentAccountSelectLabel}
        onChange={handlePaymentAccountChange}
      >
        {paymentAccounts?.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Styled.FormControl>
  );
};
