import { Box, CardHeader as MuiCardHeader, styled } from "@mui/material";

export const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.grey[200]
      : theme.palette.grey[700],
}));

export const ContentBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 2rem;
`;
