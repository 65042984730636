import { FieldValues } from "react-hook-form";
import {
  FormSelectField,
  FormSelectFieldProps,
  FormSelectFieldOptions,
} from "@/components/form/FormSelectField";
import { UserStatus } from "@/features/users/types";

const userStatusLabelMap: Record<UserStatus, string> = {
  [UserStatus.Active]: "Active",
  [UserStatus.Inactive]: "Inactive",
};

const options: FormSelectFieldOptions<UserStatus> = Object.values(
  UserStatus,
).map((status) => ({
  value: status,
  label: userStatusLabelMap[status],
}));

export const UserStatusField = <TFormValues extends FieldValues>({
  label = "Status",
  ...restProps
}: Omit<FormSelectFieldProps<TFormValues>, "options">) => (
  <FormSelectField label={label} options={options} {...restProps} />
);
