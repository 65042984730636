import {
  CheckoutSdkEvent,
  IGoBackToMerchantEventData,
  IStatusChangeEventData,
} from "./types";

const SENDER = "checkout";

// TODO: This SDK should be provided as part of the widgets.fe project
export class CheckoutSdk {
  static subscribe(
    event: CheckoutSdkEvent.StatusChange,
    handler: (data: IStatusChangeEventData) => void,
  ): () => void;

  static subscribe(
    event: CheckoutSdkEvent.GoBackToMerchant,
    handler: (data: IGoBackToMerchantEventData) => void,
  ): () => void;

  static subscribe(event: CheckoutSdkEvent, handler: () => void): () => void;

  static subscribe<TPayload>(
    event: CheckoutSdkEvent,
    handler?: (data: TPayload) => void,
  ): () => void {
    const eventHandler = (e: MessageEvent) => {
      if (e.data.sender === SENDER && e.data.event === event && handler) {
        handler(e.data);
      }
    };

    window.addEventListener("message", eventHandler);

    return () => {
      window.removeEventListener("message", eventHandler);
    };
  }
}
