import { FC, ReactNode, createContext, useCallback, useState } from "react";
import { IConfirmDialogProps, ConfirmDialog } from "@/components/ConfirmDialog";

interface IConfirmDialogProviderProps {
  children: ReactNode;
}

type IOpenConfirmDialogData = Omit<IConfirmDialogProps, "onClose">;

interface IConfirmDialogContextProps {
  showConfirmDialog: (data: IOpenConfirmDialogData) => void;
}

export const ConfirmDialogContext = createContext<IConfirmDialogContextProps>({
  showConfirmDialog: () => {},
});

export const ConfirmDialogProvider: FC<IConfirmDialogProviderProps> = ({
  children,
}) => {
  const [confirmDialogState, setConfirmDialogState] =
    useState<IOpenConfirmDialogData | null>(null);

  const showConfirmDialog = useCallback((data: IOpenConfirmDialogData) => {
    setConfirmDialogState(data);
  }, []);

  const handleCloseConfirmDialog = useCallback(() => {
    setConfirmDialogState(null);
  }, []);

  return (
    <ConfirmDialogContext.Provider value={{ showConfirmDialog }}>
      {children}
      {confirmDialogState && (
        <ConfirmDialog
          {...confirmDialogState}
          onClose={handleCloseConfirmDialog}
        />
      )}
    </ConfirmDialogContext.Provider>
  );
};
