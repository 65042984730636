import { styled } from "@mui/material";

export const Root = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Content = styled("main")`
  flex-grow: 1;
  overflow: auto;
`;
