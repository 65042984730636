import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { toast } from "react-toastify";
import { DEFAULT_ERROR_MESSAGE } from "@/constants/common";

interface IBackendError {
  status: number;
  data?: {
    errors: string | string[];
  };
}

const isFetchBaseQueryError = (
  error: unknown,
): error is FetchBaseQueryError => {
  return typeof error === "object" && error !== null && "status" in error;
};

const isBackendError = (error: FetchBaseQueryError): error is IBackendError => {
  return "data" in error;
};

export const handleError = (
  error: unknown,
  defaultErrorMessage = DEFAULT_ERROR_MESSAGE,
) => {
  let message = defaultErrorMessage;

  if (isFetchBaseQueryError(error)) {
    if ("error" in error) {
      message = error.error;
    }

    if (typeof error.data === "string") {
      message = error.data;
    }

    if (isBackendError(error)) {
      const { errors } = error.data || {};

      if (typeof errors === "string") {
        message = errors;
      } else if (Array.isArray(errors) && errors.length) {
        message = errors.join("\n");
      }
    }
  }

  toast.error(message);
  console.error(error);
};
