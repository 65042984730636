export enum Currency {
  AED = "AED",
  BHD = "BHD",
  CAD = "CAD",
  CNY = "CNY",
  EUR = "EUR",
  IDR = "IDR",
  INR = "INR",
  JPY = "JPY",
  KWD = "KWD",
  MYR = "MYR",
  OMR = "OMR",
  QAR = "QAR",
  SAR = "SAR",
  THB = "THB",
  UAH = "UAH",
  USD = "USD",
  VND = "VND",
}
