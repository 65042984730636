import { ReactElement } from "react";
import { Control, Controller, Path, FieldValues } from "react-hook-form";
import MaskedInput, { MaskedInputProps } from "react-text-mask";
import { TextField, TextFieldProps } from "@mui/material";

// TODO: "react-text-mask" should be replaced by actively maintained library, for example https://github.com/uNmAnNeR/imaskjs

export type FormTextFieldProps<TFormValues extends FieldValues> = Omit<
  TextFieldProps,
  "defaultValue" | "value"
> & {
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  maskedInputProps?: MaskedInputProps;
};

export const FormTextField = <TFormValues extends FieldValues>({
  name,
  control,
  helperText,
  maskedInputProps,
  ...restProps
}: FormTextFieldProps<TFormValues>): ReactElement => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => {
      const { value, onChange, ...restField } = field;

      const renderMuiTextField = (props?: TextFieldProps) => {
        return (
          <TextField
            error={!!error}
            helperText={error?.message || helperText}
            value={value || ""}
            onChange={onChange}
            {...restField}
            {...props}
            {...restProps}
          />
        );
      };

      if (maskedInputProps) {
        return (
          <MaskedInput
            value={value}
            onChange={onChange}
            render={(ref, props) => {
              return renderMuiTextField({ inputRef: ref, ...props });
            }}
            {...maskedInputProps}
          />
        );
      }

      return renderMuiTextField();
    }}
  />
);
