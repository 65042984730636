import { ProductCards } from "@/features/main/components/ProductCards";
import { Outlet } from "react-router-dom";
import { products } from "@/features/main/constants";
import * as Styled from "./EmulatorPage.styles";

export const EmulatorPage = () => {
  return (
    <Styled.Root>
      <Styled.Title variant="h4">Select Amount to pay</Styled.Title>
      <ProductCards items={products} />
      <Outlet />
    </Styled.Root>
  );
};
