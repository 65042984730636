import { Typography } from "@mui/material";
import * as Styled from "./Success.styles";

export const SuccessPage = () => {
  return (
    <>
      <Styled.Title variant="h4">Thank you!</Styled.Title>
      <Typography variant="subtitle1">
        Your order has been successfully placed!
      </Typography>
    </>
  );
};
