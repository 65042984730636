export enum OperationStatus {
  Success = "success",
  Error = "error",
  Pending = "pending",
}

export enum CheckoutSdkEvent {
  GoBackToMerchant = "goBackToMerchant",
  StatusChange = "statusChange",
}

export interface IGoBackToMerchantEventData {
  event: CheckoutSdkEvent.StatusChange;
  operationStatus: OperationStatus;
}

export interface IStatusChangeEventData {
  event: CheckoutSdkEvent.GoBackToMerchant;
  operationStatus: OperationStatus;
}
