import { Typography } from "@mui/material";
import * as Styled from "./Error.styles";

export const ErrorPage = () => {
  return (
    <>
      <Styled.Title variant="h4">Error</Styled.Title>
      <Typography variant="subtitle1">
        Your order has been failed. Please try again later.
      </Typography>
    </>
  );
};
