import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISelectPaymentAccountParams } from "@/components/SelectPaymentAccount";
import { FC, useCallback, useEffect } from "react";
import { handleError } from "@/utils/handleError";
import { ROUTES } from "@/routing/constants";
import { useWithdrawalInitQuery } from "@/features/withdrawal/withdrawalApi";
import { DialogCashier } from "@/components/DialogCashier";

interface IDialogWithdrawalProps {
  open: boolean;
}

type IWithdrawalPaymentParams = {
  amount: string;
  currency: string;
};
export const DialogWithdrawal: FC<IDialogWithdrawalProps> = ({ open }) => {
  const navigate = useNavigate();
  const { parsedSearchParams } = useSearchParams<ISelectPaymentAccountParams>();
  const { paymentAccountType } = parsedSearchParams;
  const { amount = "", currency = "" } = useParams<IWithdrawalPaymentParams>();

  const { data, isFetching, error } = useWithdrawalInitQuery({
    paymentAccount: paymentAccountType,
    currency: currency,
    amount: Number(amount),
  });

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  const handleClose = useCallback(() => {
    navigate(ROUTES.withdrawal.createURL(parsedSearchParams));
  }, [navigate, parsedSearchParams]);

  return (
    <DialogCashier
      title="Withdrawal"
      open={open}
      onClose={handleClose}
      isLoading={isFetching}
      iframeSrc={data?.action || ""}
      error={error}
    />
  );
};
