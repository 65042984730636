import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useCallback } from "react";
import { FormNumberField, FormSelectField } from "@/components/form";
import { WithdrawalFormName } from "@/features/withdrawal/components/WithdrawalForm/types";
import {
  currencies,
  withdrawalFormLabelMap,
} from "@/features/withdrawal/components/WithdrawalForm/constants";
import { Button } from "@/components/Button";
import { ROUTES } from "@/routing/constants";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISelectPaymentAccountParams } from "@/components/SelectPaymentAccount";
import * as Styled from "./WithdrawalForm.styles";

interface IWithdrawalFormProps {
  className?: string;
}

const formSchema = yup.object().shape({
  [WithdrawalFormName.Amount]: yup.number().required(),
  [WithdrawalFormName.Currency]: yup.string().required(),
});

type WithdrawalFormValues = yup.InferType<typeof formSchema>;

interface IWithdrawalFormSearchParams extends ISelectPaymentAccountParams {
  defaultCurrency: string;
}

export const WithdrawalForm: FC<IWithdrawalFormProps> = ({ className }) => {
  const navigate = useNavigate();
  const { parsedSearchParams } = useSearchParams<IWithdrawalFormSearchParams>();
  const { defaultCurrency } = parsedSearchParams;

  const currencyOptions = currencies.map((currency) => ({
    label: currency,
    value: currency,
  }));

  const { control, handleSubmit } = useForm<WithdrawalFormValues>({
    resolver: yupResolver<WithdrawalFormValues>(formSchema),
    mode: "onChange",
    defaultValues: { [WithdrawalFormName.Currency]: defaultCurrency },
  });

  const handleFormSubmit = useCallback(
    (values: WithdrawalFormValues) => {
      navigate(
        ROUTES.withdrawalCashier.createURL(
          values.amount,
          values.currency,
          parsedSearchParams,
        ),
      );
    },
    [navigate, parsedSearchParams],
  );

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Styled.FieldWrapper>
          <FormNumberField
            name={WithdrawalFormName.Amount}
            label={withdrawalFormLabelMap[WithdrawalFormName.Amount]}
            control={control}
          />
        </Styled.FieldWrapper>
        <Styled.FieldWrapper>
          <FormSelectField
            name={WithdrawalFormName.Currency}
            label={withdrawalFormLabelMap[WithdrawalFormName.Currency]}
            control={control}
            options={currencyOptions}
          />
        </Styled.FieldWrapper>
        <Styled.ButtonWrapper>
          <Button type="submit">Submit</Button>
        </Styled.ButtonWrapper>
      </form>
    </div>
  );
};
