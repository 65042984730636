import { FC, ReactNode } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import * as Styled from "./DialogHeader.styles";

export interface IDialogHeaderProps {
  children?: ReactNode;
  onClose?: () => void;
}

export const DialogHeader: FC<IDialogHeaderProps> = ({ children, onClose }) => (
  <Styled.DialogTitle>
    {children}
    {onClose && (
      <Styled.IconButton onClick={onClose}>
        <CloseIcon />
      </Styled.IconButton>
    )}
  </Styled.DialogTitle>
);
