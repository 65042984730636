import { FC } from "react";
import { Container, Grid } from "@mui/material";
import { ProductCard } from "@/features/main/components/ProductCard";
import { IProduct } from "@/features/main/types";

interface IProductCardsProps {
  items: Array<IProduct>;
}

export const ProductCards: FC<IProductCardsProps> = ({ items }) => {
  return (
    <Container maxWidth="md" component="main">
      <Grid container spacing={5} alignItems="flex-end">
        {items.map((item) => (
          <Grid item key={item.title} xs={12} sm={6} md={4}>
            <ProductCard {...item} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
