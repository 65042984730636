import { styled, Typography } from "@mui/material";
import { WithdrawalForm } from "@/features/withdrawal/components/WithdrawalForm";

export const Root = styled("div")`
  padding: 3rem;
`;

export const Title = styled(Typography)`
  margin-bottom: 4rem;
  text-align: center;
  text-transform: capitalize;
`;

export const Form = styled(WithdrawalForm)`
  max-width: 30rem;
  margin: 0 auto;
`;
