import { styled } from "@mui/material";

export const Root = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const NestedNodes = styled("div")`
  margin-left: 1.5rem;
`;
