import { Id, IPaginatedResponseBody } from "@/types/base";
import { UserAuthType, UserRole } from "@/types/user";
import { OperatorId } from "@/types/operator";
import { MerchantId } from "@/types/merchant";

export enum UserStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export interface IUser {
  id: Id;
  email: string;
  isEmailConfirmed: boolean;
  fullName: string;
  role: UserRole;
  status: UserStatus;
  authType: UserAuthType;
  operatorId: OperatorId | null;
  merchantId: MerchantId | null;
  operatorIdBlackList: OperatorId[];
  merchantIdBlackList: MerchantId[];
  ipWhiteList: string[];
  createdAt: string;
  updatedAt: string;
}

export type Users = IUser[];

export interface IGetUsersRequestParams {
  pageSize?: number;
  filters?: {
    search?: string;
  };
}

export interface IGetUsersResponseBody extends IPaginatedResponseBody<Users> {}

export interface ICreateUserRequestData
  extends Pick<
    IUser,
    "email" | "fullName" | "authType" | "status" | "role" | "ipWhiteList"
  > {
  operatorId?: OperatorId | null;
  merchantId?: MerchantId | null;
  operatorIdBlackList?: OperatorId[];
  merchantIdBlackList?: MerchantId[];
}

export interface IUpdateUserRequestData
  extends Pick<
    IUser,
    "id" | "email" | "fullName" | "authType" | "status" | "ipWhiteList"
  > {
  operatorIdBlackList?: OperatorId[];
  merchantIdBlackList?: MerchantId[];
}

export interface ISwitchUserStatusResponseBody extends IUser {}

export interface ISwitchUserStatusRequestData {
  id: Id;
  status: UserStatus;
}