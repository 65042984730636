import { forwardRef } from "react";
import {
  GlobalStyles,
  CssBaseline,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from "react-router-dom";
import { Router } from "@/routing/Router";
import "react-toastify/dist/ReactToastify.css";
import { globalStyles } from "./styles/global";
import { ConfirmDialogProvider } from "@/contexts/ConfirmDialogContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// TODO: Move theme creation to the separate file
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<ReactRouterLinkProps, "to"> & { href: ReactRouterLinkProps["to"] }
>(({ href, ...restProps }, ref) => {
  // Map href (Material UI) -> to (react-router-dom)
  return <ReactRouterLink ref={ref} to={href} {...restProps} />;
});

const darkTheme = createTheme({
  components: {
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiTab: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true,
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        spacing: {
          gap: "0.5rem",
        },
      },
    },
  },
});

export const App = () => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />
        <ConfirmDialogProvider>
          <Router />
        </ConfirmDialogProvider>
      <ToastContainer />
    </ThemeProvider>
  </LocalizationProvider>
);
