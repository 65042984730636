import { api } from "@/store/api";
import {
  IDepositInitRequestBody,
  IDepositInitResponseBody,
} from "@/features/main/types";

const appApi = api.injectEndpoints({
  endpoints: (builder) => ({
    depositInit: builder.query<
      IDepositInitResponseBody,
      IDepositInitRequestBody
    >({
      query: (product: IDepositInitRequestBody) => ({
        url: "deposit/init",
        method: "POST",
        body: product,
      }),
    }),
  }),
  overrideExisting: false,
});
export const { useDepositInitQuery } = appApi;
