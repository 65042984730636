import { FC } from "react";
import { Outlet } from "react-router-dom";
import { AppBar } from "@/components/AppBar";
import * as Styled from "./AppLayout.styles";

export const AppLayout: FC = () => {
  return (
    <Styled.Root>
      <Styled.Container>
        <AppBar />
        <Styled.Content>
          <Outlet />
        </Styled.Content>
      </Styled.Container>
    </Styled.Root>
  );
};
