import { FC } from "react";
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import { Button } from "@/components/Button";
import { formatCurrency } from "@/utils/dataFormat/formatCurrency";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISelectPaymentAccountParams } from "@/components/SelectPaymentAccount";
import { ROUTES } from "@/routing/constants";
import { IProduct } from "@/features/main/types";
import * as Styled from "./ProductCard.styles";

export const ProductCard: FC<IProduct> = ({
  id,
  title,
  tooltip,
  price,
  currency,
  buttonText,
  description,
}) => {
  const { parsedSearchParams } = useSearchParams<ISelectPaymentAccountParams>();

  return (
    <Card>
      <Styled.CardHeader
        title={
          <>
            {title}
            {tooltip && (
              <Tooltip title={tooltip}>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
        titleTypographyProps={{ align: "center" }}
      />
      <CardContent>
        <Styled.ContentBox>
          <Typography variant="h3" color="text.primary">
            {formatCurrency(price, currency)}
          </Typography>
        </Styled.ContentBox>
        <ul>
          {description?.map((line) => (
            <Typography component="li" variant="subtitle1" key={line}>
              {line}
            </Typography>
          ))}
        </ul>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          variant="outlined"
          href={ROUTES.payment.createURL(id, parsedSearchParams)}
        >
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  );
};
