import { FC, useCallback, useEffect, useState } from "react";
import { CircularProgress, Dialog } from "@mui/material";
import { DialogHeader } from "@/components/DialogHeader";
import { toast } from "react-toastify";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import {
  CheckoutSdk,
  OperationStatus,
  CheckoutSdkEvent,
} from "@/services/CheckoutSdk";
import * as Styled from "./DialogCashier.styles";

interface IDialogCashierProps {
  title: string;
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  iframeSrc: string;
  error: FetchBaseQueryError | SerializedError | undefined;
}

export const DialogCashier: FC<IDialogCashierProps> = ({
  title,
  open,
  onClose,
  isLoading,
  iframeSrc,
  error,
}) => {
  const [operationStatus, setOperationStatus] =
    useState<OperationStatus | null>(null);

  const handleClose = useCallback(
    (operationStatus: OperationStatus | null) => {
      onClose();

      if (!operationStatus) {
        return;
      }

      const handlerByOperationStatus: Record<OperationStatus, () => void> = {
        [OperationStatus.Success]: () => {
          toast.success(`Thank you! Your order has been successfully placed!`, {
            autoClose: false,
          });
        },
        [OperationStatus.Error]: () => {
          toast.error(
            `Error. Your order has been failed. Please try again later.`,
            { autoClose: false },
          );
        },
        [OperationStatus.Pending]: () => {
          toast.warning(
            `Your operation is in progress. You can check the status later in the personal cabinet.`,
            { autoClose: false },
          );
        },
      };

      handlerByOperationStatus[operationStatus]?.();
    },
    [onClose],
  );

  useEffect(() => {
    return CheckoutSdk.subscribe(CheckoutSdkEvent.StatusChange, (data) => {
      setOperationStatus(data.operationStatus);
    });
  }, []);

  useEffect(() => {
    return CheckoutSdk.subscribe(CheckoutSdkEvent.GoBackToMerchant, (data) => {
      setOperationStatus(data.operationStatus);
      handleClose(data.operationStatus);
    });
  }, [handleClose]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(operationStatus)}
      maxWidth={false}
    >
      <DialogHeader onClose={() => handleClose(operationStatus)}>
        {title}
      </DialogHeader>
      <Styled.DialogContent dividers>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          "Something went wrong. Please try again later."
        ) : (
          <Styled.IFrame src={iframeSrc}></Styled.IFrame>
        )}
      </Styled.DialogContent>
    </Dialog>
  );
};
