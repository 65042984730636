import { FieldValues } from "react-hook-form";
import {
  FormSelectField,
  FormSelectFieldProps,
  FormSelectFieldOptions,
} from "@/components/form/FormSelectField";
import { UserAuthType } from "@/types/user";

const userAuthTypeLabelMap: Record<UserAuthType, string> = {
  [UserAuthType.None]: "Without 2FA",
  [UserAuthType.Email]: "Email 2FA",
  [UserAuthType.GoogleAuthenticator]: "Google Authenticator 2FA",
};

const options: FormSelectFieldOptions<UserAuthType> = Object.values(
  UserAuthType,
).map((authType) => ({
  value: authType,
  label: userAuthTypeLabelMap[authType],
}));

export const UserAuthTypeField = <TFormValues extends FieldValues>({
  label = "Auth type",
  ...restProps
}: Omit<FormSelectFieldProps<TFormValues>, "options">) => (
  <FormSelectField label={label} options={options} {...restProps} />
);
