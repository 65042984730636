import { api } from "@/store/api";
import {
  IWithdrawalInitRequestBody,
  IWithdrawalInitResponseBody,
} from "@/features/withdrawal/types";

const appApi = api.injectEndpoints({
  endpoints: (builder) => ({
    withdrawalInit: builder.query<
      IWithdrawalInitResponseBody,
      IWithdrawalInitRequestBody
    >({
      query: (product: IWithdrawalInitRequestBody) => ({
        url: "withdrawal/init",
        method: "POST",
        body: product,
      }),
    }),
  }),
  overrideExisting: false,
});
export const { useWithdrawalInitQuery } = appApi;
