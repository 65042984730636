import qs, { IStringifyOptions } from "qs";
import { Id } from "@/types/base";
import { TransactionId, TransactionType } from "@/types/transaction";

const USERS_ROOT_PATH = "/users";
const TRANSACTIONS_ROOT_PATH = "/transactions-manager/:transactionType";
const MAIN_ROOT_PATH = "/main";
const STATUS_ROOT_PATH = "/status";
const WITHDRAWAL_ROOT_PATH = "/withdrawal";

const QS_STRINGIFY_OPTIONS: IStringifyOptions = {
  addQueryPrefix: true,
};

export const ROUTES = {
  main: {
    path: MAIN_ROOT_PATH,
    createURL: (searchParams?: Record<string, any>) => {
      const searchString = qs.stringify(searchParams, QS_STRINGIFY_OPTIONS);
      return `${ROUTES.main.path}${searchString}`;
    },
  },
  withdrawal: {
    path: WITHDRAWAL_ROOT_PATH,
    createURL: (searchParams?: Record<string, any>) => {
      const searchString = qs.stringify(searchParams, QS_STRINGIFY_OPTIONS);
      return `${ROUTES.withdrawal.path}${searchString}`;
    },
  },
  withdrawalCashier: {
    path: `${WITHDRAWAL_ROOT_PATH}/cashier/:amount/:currency`,
    createURL: (
      amount: number,
      currency: string,
      searchParams?: Record<string, any>,
    ) => {
      const searchString = qs.stringify(searchParams, QS_STRINGIFY_OPTIONS);
      return `${ROUTES.withdrawalCashier.path
        .replace(":amount", amount.toString())
        .replace(":currency", currency)}${searchString}`;
    },
  },
  payment: {
    path: `${MAIN_ROOT_PATH}/payment/:id`,
    createURL: (id: string, searchParams?: Record<string, any>) => {
      const searchString = qs.stringify(searchParams, QS_STRINGIFY_OPTIONS);

      return `${ROUTES.payment.path.replace(":id", id)}${searchString}`;
    },
  },
  status: {
    path: STATUS_ROOT_PATH,
    createURL: () => ROUTES.status.path,
  },
  error: {
    path: `${STATUS_ROOT_PATH}/error`,
    createURL: () => ROUTES.error.path,
  },
  success: {
    path: `${STATUS_ROOT_PATH}/success`,
    createURL: () => ROUTES.success.path,
  },
  transactionsManager: {
    path: TRANSACTIONS_ROOT_PATH,
    createURL: (
      transactionType = TransactionType.Deposit,
      searchParams?: Record<string, any>,
    ) => {
      const searchString = qs.stringify(searchParams, QS_STRINGIFY_OPTIONS);

      return `${ROUTES.transactionsManager.path.replace(
        ":transactionType",
        transactionType,
      )}${searchString}`;
    },
  },
  users: {
    path: USERS_ROOT_PATH,
    createURL: (searchString = "") => {
      return `${ROUTES.users.path}${searchString}`;
    },
  },
  usersCreate: {
    path: `${USERS_ROOT_PATH}/create`,
    createURL: (searchString = "") => {
      return `${ROUTES.usersCreate.path}${searchString}`;
    },
  },
  usersEdit: {
    path: `${USERS_ROOT_PATH}/:id/edit`,
    createURL: (id: Id, searchString = "") => {
      return `${ROUTES.usersEdit.path.replace(
        ":id",
        id.toString(),
      )}${searchString}`;
    },
  },
  transactionsDetails: {
    path: `${TRANSACTIONS_ROOT_PATH}/:id`,
    createURL: (
      id: TransactionId,
      transactionType: TransactionType,
      searchParams?: Record<string, any>,
    ) => {
      const searchString = qs.stringify(searchParams, QS_STRINGIFY_OPTIONS);

      return `${ROUTES.transactionsDetails.path
        .replace(":id", id.toString())
        .replace(":transactionType", transactionType)}${searchString}`;
    },
  },
} as const;
