import { Outlet } from "react-router-dom";
import * as Styled from "./StatusPageLayout.styles";

export const StatusPageLayout = () => {
  return (
    <Styled.Root>
      <Outlet />
    </Styled.Root>
  );
};
