import { DialogContent as MuiDialogContent, styled } from "@mui/material";

export const DialogContent = styled(MuiDialogContent)`
  min-width: 55rem;
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IFrame = styled("iframe")`
  width: 100%;
  height: 95%;
`;
