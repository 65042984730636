import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithRefreshToken } from "./baseQueryWithRefreshToken";
import { ApiTag } from "@/types/api";

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithRefreshToken,
  tagTypes: Object.values(ApiTag),
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
});
