import { IProduct } from "@/features/main/types";

export const products: IProduct[] = [
  {
    id: "1",
    title: "Basic",
    price: 5,
    currency: "USD",
    description: [
      "10 users included",
      "2 GB of storage",
      "Help center access",
      "Email support",
    ],
    buttonText: "Buy",
  },
  {
    id: "2",
    title: "Pro",
    price: 15,
    currency: "USD",
    description: [
      "20 users included",
      "10 GB of storage",
      "Help center access",
      "Priority email support",
    ],
    buttonText: "Buy",
  },
  {
    id: "3",
    title: "Enterprise",
    price: 30,
    currency: "USD",
    description: [
      "50 users included",
      "30 GB of storage",
      "Help center access",
      "Phone & email support",
    ],
    buttonText: "Buy",
  },
  {
    id: "4",
    title: "Super",
    tooltip: "Bank Card: Pending(Declined with 35-second delay)",
    price: 0.96,
    currency: "EUR",
    description: [
      "50 users included",
      "30 GB of storage",
      "Help center access",
      "Phone & email support",
    ],
    buttonText: "Buy",
  },
  {
    id: "5",
    title: "Max",
    tooltip: "Bank Card: Success after 25s pending",
    price: 0.94,
    currency: "EUR",
    description: [
      "50 users included",
      "30 GB of storage",
      "Help center access",
      "Phone & email support",
    ],
    buttonText: "Buy",
  },
];

export const productById = products.reduce(
  (accumulator, product) => {
    return {
      ...accumulator,
      [product.id]: product,
    };
  },
  {} as Record<number, IProduct>,
);
