import { styled, Toolbar as MuiToolbar } from "@mui/material";
import { NavLink as NavLinkBase } from "react-router-dom";

export const Toolbar = styled(MuiToolbar)`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const Wrapper = styled("div")`
  display: flex;
  align-items: center;
`;

export const Nav = styled("nav")`
  display: flex;
  margin: 0.2rem 0 0 3rem;
`;

export const NavLink = styled(NavLinkBase)(({ theme }) => {
  const { transitions, palette } = theme;

  return {
    marginRight: "1rem",
    textDecoration: "none",
    color: palette.common.white,
    opacity: 0.7,
    transition: transitions.create(["opacity", "color"]),

    "&:hover, &:focus": {
      opacity: 1,
    },

    "&:active": {
      opacity: 0.6,
    },

    "&.active, &.active:hover, &.active:focus": {
      opacity: 1,
      textDecoration: "underline",
      textUnderlinePosition: "under",
    },
  };
});
