import { FormControl as MuiFormControl, styled } from "@mui/material";

export const FormControl = styled(MuiFormControl)`
  max-width: 18rem;

  // Todo: PGW-76 | find better way to change focus color for formControl
  .Mui-focused {
    color: ${({ theme }) => theme.palette.common.white} !important;

    * {
      color: ${({ theme }) => theme.palette.common.white};
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.common.white} !important;
    }
  }
`;
