import { useCallback, useMemo } from "react";
import { useSearchParams as useReactRouterSearchParams } from "react-router-dom";
import qs from "qs";
import { isDate } from "date-fns";

type AnyObject = Record<string, any>;

export const useSearchParams = <TParsedSearchParams extends AnyObject>() => {
  const [searchParams, setSearchParams] = useReactRouterSearchParams();

  const queryString = searchParams.toString();

  const parsedSearchParams = useMemo(() => {
    return qs.parse(queryString) as TParsedSearchParams;
  }, [queryString]);

  const getMergedSearchParams = useCallback(
    (newParams: Partial<TParsedSearchParams>) => {
      return deepMerge(parsedSearchParams, newParams);
    },
    [parsedSearchParams],
  );

  const mergeSearchParams = useCallback(
    (newParams: Partial<TParsedSearchParams>) => {
      const mergedSearchParams = getMergedSearchParams(newParams);
      const newSearchParamsString = qs.stringify(mergedSearchParams);

      setSearchParams(newSearchParamsString);
    },
    [getMergedSearchParams, setSearchParams],
  );

  return {
    parsedSearchParams,
    getMergedSearchParams,
    mergeSearchParams,
  };
};

const isObject = (item: any): boolean => {
  return item !== null && typeof item === "object" && !Array.isArray(item);
};

const deepMerge = <TData extends AnyObject>(
  objectA: TData,
  objectB: TData,
): TData => {
  const resultObject = { ...objectA };

  for (const keyB in objectB) {
    const valueB = objectB[keyB];

    if (isDate(valueB)) {
      resultObject[keyB] = valueB;
      continue;
    }

    if (isObject(valueB)) {
      resultObject[keyB] = deepMerge(resultObject[keyB], valueB);
      continue;
    }

    resultObject[keyB] = valueB;
  }

  return resultObject;
};
