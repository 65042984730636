import { Outlet } from "react-router-dom";
import * as Styled from "./Withdrawal.styles";

export const WithdrawalPage = () => {
  return (
    <Styled.Root>
      <Styled.Title variant="h4">Enter amount for withdrawal</Styled.Title>
      <Styled.Form />
      <Outlet />
    </Styled.Root>
  );
};
