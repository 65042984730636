import { FC } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AppLayout } from "@/components/AppLayout";
import { NoMatch } from "@/components/NoMatch";
import { ROUTES } from "./constants";
import { EmulatorPage } from "@/features/main/pages/EmulatorPage";
import { DialogPayment } from "@/features/main/components/DialogPayment";
import { SuccessPage } from "@/features/successPage/pages";
import { ErrorPage } from "@/features/errorPage/pages";
import { StatusPageLayout } from "@/components/StatusPageLayout";
import { WithdrawalPage } from "@/features/withdrawal/pages/WithdrawalPage";
import { DialogWithdrawal } from "@/features/withdrawal/components/DialogWithdrawal";

const DEFAULT_PATH = ROUTES.main.createURL();

export const Router: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route index element={<Navigate to={DEFAULT_PATH} />} />
        <Route path={ROUTES.main.path} element={<EmulatorPage />}>
          <Route
            path={ROUTES.payment.path}
            element={<DialogPayment open={true} />}
          />
        </Route>
        <Route path={ROUTES.withdrawal.path} element={<WithdrawalPage />}>
          <Route
            path={ROUTES.withdrawalCashier.path}
            element={<DialogWithdrawal open={true} />}
          />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Route>
      <Route path={ROUTES.status.path} element={<StatusPageLayout />}>
        <Route path={ROUTES.success.path} element={<SuccessPage />} />
        <Route path={ROUTES.error.path} element={<ErrorPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
