import { FC } from "react";
import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  CircularProgress,
} from "@mui/material";

export interface IButtonProps extends MUIButtonProps {
  isLoading?: boolean;
}

export const Button: FC<IButtonProps> = ({
  isLoading,
  endIcon,
  size,
  disabled,
  ...restProps
}) => (
  <MUIButton
    endIcon={
      isLoading ? (
        <CircularProgress
          color="inherit"
          size={getCircularProgressSize(size)}
        />
      ) : (
        endIcon
      )
    }
    size={size}
    disabled={isLoading || disabled}
    {...restProps}
  />
);

const getCircularProgressSize = (size: IButtonProps["size"]) => {
  switch (size) {
    case "small":
      return "0.8rem";
    case "large":
      return "1rem";
    case "medium":
    default:
      return "0.875rem";
  }
};
