import { styled, Typography } from "@mui/material";

export const Root = styled("div")`
  padding: 3rem;
`;

export const Title = styled(Typography)`
  margin-bottom: 4rem;
  text-align: center;
  text-transform: capitalize;
`;
