import { FC } from "react";
import { AppBar as MuiAppBar, Typography } from "@mui/material";
import { APP_NAME } from "@/constants/common";
import { SelectPaymentAccount } from "@/components/SelectPaymentAccount";
import { ROUTES } from "@/routing/constants";
import { Currency } from "@/types/currency";
import * as Styled from "./AppBar.styles";

const DEFAULT_CURRENCY = Currency.USD;

export const AppBar: FC = () => {
  return (
    <MuiAppBar position="static">
      <Styled.Toolbar>
        <Styled.Wrapper>
          <Typography variant="h6" noWrap>
            {APP_NAME}
          </Typography>
          <Styled.Nav>
            <Styled.NavLink to={ROUTES.main.createURL()}>
              Deposit
            </Styled.NavLink>
            <Styled.NavLink
              to={ROUTES.withdrawal.createURL({
                defaultCurrency: DEFAULT_CURRENCY,
              })}
            >
              Withdrawal
            </Styled.NavLink>
          </Styled.Nav>
        </Styled.Wrapper>
        <SelectPaymentAccount />
      </Styled.Toolbar>
    </MuiAppBar>
  );
};
