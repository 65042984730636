import { FC, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@/routing/constants";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISelectPaymentAccountParams } from "@/components/SelectPaymentAccount";
import { useDepositInitQuery } from "@/features/main/mainApi";
import { productById } from "@/features/main/constants";
import { handleError } from "@/utils/handleError";
import { DialogCashier } from "@/components/DialogCashier";

interface IDialogPaymentProps {
  open: boolean;
}

type IDialogPaymentParams = {
  id: string;
};

export const DialogPayment: FC<IDialogPaymentProps> = ({ open }) => {
  const navigate = useNavigate();
  const { parsedSearchParams } = useSearchParams<ISelectPaymentAccountParams>();
  const { paymentAccountType } = parsedSearchParams;
  const { id = "" } = useParams<IDialogPaymentParams>();
  const product = productById[Number(id)];

  const { data, isFetching, error } = useDepositInitQuery({
    paymentAccount: paymentAccountType,
    currency: product.currency,
    amount: product.price,
  });

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  const handleClose = useCallback(() => {
    navigate(ROUTES.main.createURL(parsedSearchParams));
  }, [navigate, parsedSearchParams]);

  return (
    <DialogCashier
      open={open}
      title="Payment"
      isLoading={isFetching}
      iframeSrc={data?.action || ""}
      error={error}
      onClose={handleClose}
    />
  );
};
