import { FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import qs from "qs";
import { API_BASE_URL } from "@/constants/common";

const mutex = new Mutex();

const ENDPOINTS_TO_IGNORE_401 = ["/auth/logout"];

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  credentials: "include",
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});

const getUrlFromArgs = (args: string | FetchArgs) => {
  if (typeof args === "string") {
    return args;
  }

  return args.url;
};

export const baseQueryWithRefreshToken: typeof baseQuery = async (
  args,
  baseQueryApi,
  extraOptions,
) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, baseQueryApi, extraOptions);

  const url = getUrlFromArgs(args);
  const shouldIgnore401 = ENDPOINTS_TO_IGNORE_401.includes(url);

  if (!shouldIgnore401 && result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      const refreshToken = () =>
        baseQuery(
          { url: "/auth/refresh", method: "POST" },
          baseQueryApi,
          extraOptions,
        );

      const retryInitialQuery = () =>
        baseQuery(args, baseQueryApi, extraOptions);

      try {
        const refreshResult = await refreshToken();

        if (refreshResult.data) {
          result = await retryInitialQuery();
        } else {
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();

      result = await baseQuery(args, baseQueryApi, extraOptions);
    }
  }

  return result;
};
