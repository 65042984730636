import { styled } from "@mui/material";

export const FieldWrapper = styled("div")`
  margin-bottom: 1rem;
`;

export const ButtonWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;
