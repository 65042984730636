import { useMemo } from "react";
import { FieldValues } from "react-hook-form";
import { createNumberMask } from "text-mask-addons";
import {
  FormTextField,
  FormTextFieldProps,
} from "@/components/form/FormTextField";

interface IFormNumberFieldProps<TFormValues extends FieldValues>
  extends FormTextFieldProps<TFormValues> {
  allowDecimal?: boolean;
  allowNegative?: boolean;
  includeThousandsSeparator?: boolean;
  prefix?: string;
}

export const FormNumberField = <TFormValues extends FieldValues>({
  allowDecimal,
  allowNegative,
  includeThousandsSeparator = false,
  prefix = "",
  ...restProps
}: IFormNumberFieldProps<TFormValues>) => {
  const mask = useMemo(() => {
    return createNumberMask({
      allowDecimal,
      allowNegative,
      prefix,
      includeThousandsSeparator,
    });
  }, [allowDecimal, allowNegative, includeThousandsSeparator, prefix]);

  return (
    <FormTextField
      maskedInputProps={{
        mask,
      }}
      {...restProps}
    />
  );
};
