import { FC, useCallback, useState } from "react";
import { Button } from "@/components/Button";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { handleError } from "@/utils/handleError";

export interface IConfirmDialogProps {
  message: string;
  onConfirm: () => void | Promise<void>;
  onClose: () => void;
}

export const ConfirmDialog: FC<IConfirmDialogProps> = ({
  message,
  onConfirm,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnConfirm = useCallback(async () => {
    try {
      setIsLoading(true);
      await onConfirm();
      onClose();
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, [onConfirm, onClose]);

  return (
    <Dialog open={true}>
      <DialogTitle>{message}</DialogTitle>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={handleOnConfirm} isLoading={isLoading}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
